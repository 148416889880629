<template>
  <form class="opportunity-monetization-section" @submit.prevent>
    <UiContainer large class="tw-mt-8 tw-pb-24">
      <v-card class="tw-p-6">
        <UiTitle :title="$t('opportunity.configuration.monetization.title')" class="tw-mb-8" large />

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.monetization.currency.label')"
          :description="$t('opportunity.configuration.monetization.currency.description')"
        >
          <template #content>
            <v-select
              class="tw-mt-4"
              v-model="monetizationModel.currency"
              flat
              dense
              outlined
              clearable
              :items="currencies"
              :placeholder="$t('opportunity.configuration.monetization.currency.label')"
            />
          </template>
        </OpportunityFieldsTitleDescription>

        <OpportunityFieldsTitleDescription
          :label="$t('opportunity.configuration.monetization.average.label')"
          :description="$t('opportunity.configuration.monetization.average.description')"
        >
          <template #content>
            <ui-input-price-in-cent
              :value-in-cents="averageValueInCents"
              :currency="monetizationModel.currency || currencies[0]"
              :locale="locale"
              :clearable="true"
              @onPriceChange="updateAverageValue"
              @clear="clearAverageValue"
            />
          </template>
        </OpportunityFieldsTitleDescription>
      </v-card>

      <UiActions v-if="isModified" centered>
        <v-btn color="primary" type="submit" large @click="updateMonetization">
          {{ $t('opportunity.configuration.monetization.save') }}
        </v-btn>
      </UiActions>
    </UiContainer>
  </form>
</template>

<script>
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import { currencies } from '@/config/currencies.config'
import UiContainer from '@/components/UI/Container.vue'
import UiInputPriceInCent from '@/components/UI/input/PriceInCent.vue'
import OpportunityFieldsTitleDescription from '@/components/Opportunity/Fields/TitleDescription.vue'

export default {
  props: {
    monetization: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  components: {
    UiInputPriceInCent,
    UiActions,
    UiTitle,
    UiContainer,
    OpportunityFieldsTitleDescription,
  },
  data() {
    return {
      currencies,
      monetizationModel: {
        currency: null,
        averageValue: null,
      },
    }
  },
  created() {
    this.monetizationModel = {
      ...this.monetizationModel,
      ...this.monetization,
    }
  },
  methods: {
    updateMonetization() {
      this.$emit('update', this.monetizationModel)
    },
    updateAverageValue(price) {
      this.monetizationModel.averageValue = price
    },
    clearAverageValue() {
      this.monetizationModel.averageValue = null
    },
  },
  computed: {
    averageValueInCents() {
      return this.monetizationModel.averageValue
    },
    isModified() {
      return JSON.stringify(this.monetization) !== JSON.stringify(this.monetizationModel)
    },
  },
}
</script>
